import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Img from "gatsby-image"

//Components
import SEO from "../components/seo"
import Layout from "../components/layout"
import Header from "../components/navigation/header"
import Footer from "../components/footer"
import Title from "../components/title"

// Styles
import styles from "../styles/components/landing/articles.module.scss"

const Articles = props => {
  const { currentPage, numPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()
  return (
    <div>
      <Header />
      <SEO
        title="Articles"
        article={true}
        pathname="/articles"
        description="Talk to us about how you can earn and save money every month with solar!"
      />
      <Layout>
        <section>
          <div className={styles.main}>
            <Title
              text="Articles &amp; Publications"
              subtitle="Read from our newsletters, articles, press releases and highlights from our social media channels"
            />
          </div>
          <div className={styles.grid}>
            {props.data.allContentfulArticle.edges.map((item, i) => (
              <div className={styles.item} key={i}>
                <Link to={`/articles/${item.node.slug}`}>
                  <Img
                    fixed={item.node.featuredImage.fixed}
                    alt={item.node.title}
                    style={{
                      height: "344px",
                      width: "100%",
                    }}
                  />
                </Link>

                <div className={styles.content}>
                  <h1 className={styles.title}>{item.node.title}</h1>

                  <h3 className={styles.date}>{item.node.date}</h3>
                  <p className={styles.desc}>{item.node.shortDescription}</p>
                  <Link
                    to={`/articles/${item.node.slug}`}
                    className={styles.btnWrapper}
                  >
                    <button className={styles.btn}>Read Article</button>
                  </Link>
                </div>
              </div>
            ))}
          </div>

          {/* Pagination */}
          <div className={styles.pagination}>
            {!isFirst && (
              <Link to={`articles/${prevPage}`} rel="prev">
                ← Previous Page
              </Link>
            )}
            {!isLast && (
              <Link to={`articles/${nextPage}`} rel="next">
                Next Page →
              </Link>
            )}
          </div>
        </section>
      </Layout>
      <Footer />
    </div>
  )
}

export const articleQuery = graphql`
  query articleQuery($limit: Int!, $skip: Int!) {
    allContentfulArticle(
      sort: { fields: [date], order: [DESC] }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          slug
          date(formatString: "MMMM, YYYY")
          shortDescription
          featuredImage {
            fixed(width: 500, quality: 100) {
              ...GatsbyContentfulFixed
            }
          }
        }
      }
    }
  }
`

export default Articles
